@import url(font-awesome.min.css);
@import url("https://fonts.googleapis.com/css?family=Raleway:800|Source+Sans+Pro:300,300i,600,600i");

:root {
  --main-color: 0, 0, 0;
  --main-color-hex: #ccc;
  --main-color-rgb: 0, 0, 0;
  --banner-image: "url(https://storage.googleapis.com/invoicing-247-sites/defaults/default-banner-0.jpeg)";
  --background-image: "url(https://storage.googleapis.com/invoicing-247-sites/defaults/default-background-0.png)";
}

/*
	Relativity by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  -webkit-text-size-adjust: none;
}

mark {
  background-color: transparent;
  color: inherit;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input,
select,
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

/* Basic */

@-ms-viewport {
  width: device-width;
}

body {
  -ms-overflow-style: scrollbar;
}

@media screen and (max-width: 480px) {
  html,
  body {
    min-width: 320px;
    overflow-x: hidden;
  }
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background-color: #3f3f3f !important;
}

body.is-preload *,
body.is-preload *:before,
body.is-preload *:after {
  -moz-animation: none !important;
  -webkit-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
  -moz-transition: none !important;
  -webkit-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
}

/* Type */

html {
  font-size: 16pt;
}

@media screen and (max-width: 1680px) {
  html {
    font-size: 13pt;
  }
}

@media screen and (max-width: 1280px) {
  html {
    font-size: 12pt;
  }
}

@media screen and (max-width: 736px) {
  html {
    font-size: 11pt;
  }
}

@media screen and (max-width: 360px) {
  html {
    font-size: 10.5pt;
  }
}

body {
  background-color: #ffffff;
  color: #3f3f3f;
}

body,
input,
select,
textarea {
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.65;
}

a {
  -moz-transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  border-bottom: dotted 1px;
  text-decoration: none;
}

a:before,
a strong {
  -moz-transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
}

a:hover {
  border-bottom-color: transparent;
}

a:hover:before,
a:hover strong {
  color: inherit !important;
}

strong,
b {
  font-weight: 600;
}

em,
i {
  font-style: italic;
}

p {
  margin: 0 0 2rem 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", Helvetica, sans-serif;
  font-weight: 800;
  line-height: 1.5;
  margin: 0 0 1rem 0;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
  text-decoration: none;
}

h1 {
  font-size: 2.75rem;
  line-height: 1.3;
}

h2 {
  font-size: 2.25rem;
  line-height: 1.3;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.1rem;
}

h5 {
  font-size: 0.9rem;
}

h6 {
  font-size: 0.7rem;
}

@media screen and (max-width: 736px) {
  h1 {
    font-size: 2.25rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1rem;
  }

  h4 {
    font-size: 0.9rem;
  }

  h5 {
    font-size: 0.8rem;
  }

  h6 {
    font-size: 0.7rem;
  }
}

sub {
  font-size: 0.8rem;
  position: relative;
  top: 0.5rem;
}

sup {
  font-size: 0.8rem;
  position: relative;
  top: -0.5rem;
}

blockquote {
  border-left: solid 4px;
  font-style: italic;
  margin: 0 0 2rem 0;
  padding: 0.5rem 0 0.5rem 2rem;
}

code {
  border-radius: 4px;
  border: solid 1px;
  font-family: "Courier New", monospace;
  font-size: 0.9rem;
  margin: 0 0.25rem;
  padding: 0.25rem 0.65rem;
}

pre {
  -webkit-overflow-scrolling: touch;
  font-family: "Courier New", monospace;
  font-size: 0.9rem;
  margin: 0 0 2rem 0;
}

pre code {
  display: block;
  line-height: 1.75;
  padding: 1rem 1.5rem;
  overflow-x: auto;
}

hr {
  border: 0;
  border-bottom: solid 1px;
  margin: 2rem 0;
}

hr.major {
  margin: 3rem 0;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

input,
select,
textarea {
  color: #3f3f3f;
}

a {
  color: inherit;
}

a:hover {
  color: var(--main-color-hex) !important;
}

strong,
b {
  color: #3f3f3f;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #3f3f3f;
}

blockquote {
  border-left-color: rgba(143, 143, 143, 0.25);
}

code {
  background: rgba(143, 143, 143, 0.075);
  border-color: rgba(143, 143, 143, 0.25);
}

hr {
  border-bottom-color: rgba(143, 143, 143, 0.25);
}

/* Row */

.row {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  align-items: stretch;
}

.row > * {
  box-sizing: border-box;
}

.row.gtr-uniform > * > :last-child {
  margin-bottom: 0;
}

.row.aln-left {
  justify-content: flex-start;
}

.row.aln-center {
  justify-content: center;
}

.row.aln-right {
  justify-content: flex-end;
}

.row.aln-top {
  align-items: flex-start;
}

.row.aln-middle {
  align-items: center;
}

.row.aln-bottom {
  align-items: flex-end;
}

.row > .imp {
  order: -1;
}

.row > .col-1 {
  width: 8.3333333333%;
}

.row > .off-1 {
  margin-left: 8.3333333333%;
}

.row > .col-2 {
  width: 16.6666666667%;
}

.row > .off-2 {
  margin-left: 16.6666666667%;
}

.row > .col-3 {
  width: 25%;
}

.row > .off-3 {
  margin-left: 25%;
}

.row > .col-4 {
  width: 33.3333333333%;
}

.row > .off-4 {
  margin-left: 33.3333333333%;
}

.row > .col-5 {
  width: 41.6666666667%;
}

.row > .off-5 {
  margin-left: 41.6666666667%;
}

.row > .col-6 {
  width: 50%;
}

.row > .off-6 {
  margin-left: 50%;
}

.row > .col-7 {
  width: 58.3333333333%;
}

.row > .off-7 {
  margin-left: 58.3333333333%;
}

.row > .col-8 {
  width: 66.6666666667%;
}

.row > .off-8 {
  margin-left: 66.6666666667%;
}

.row > .col-9 {
  width: 75%;
}

.row > .off-9 {
  margin-left: 75%;
}

.row > .col-10 {
  width: 83.3333333333%;
}

.row > .off-10 {
  margin-left: 83.3333333333%;
}

.row > .col-11 {
  width: 91.6666666667%;
}

.row > .off-11 {
  margin-left: 91.6666666667%;
}

.row > .col-12 {
  width: 100%;
}

.row > .off-12 {
  margin-left: 100%;
}

.row.gtr-0 {
  margin-top: 0;
  margin-left: 0rem;
}

.row.gtr-0 > * {
  padding: 0 0 0 0rem;
}

.row.gtr-0.gtr-uniform {
  margin-top: 0rem;
}

.row.gtr-0.gtr-uniform > * {
  padding-top: 0rem;
}

.row.gtr-25 {
  margin-top: 0;
  margin-left: -0.375rem;
}

.row.gtr-25 > * {
  padding: 0 0 0 0.375rem;
}

.row.gtr-25.gtr-uniform {
  margin-top: -0.375rem;
}

.row.gtr-25.gtr-uniform > * {
  padding-top: 0.375rem;
}

.row.gtr-50 {
  margin-top: 0;
  margin-left: -0.75rem;
}

.row.gtr-50 > * {
  padding: 0 0 0 0.75rem;
}

.row.gtr-50.gtr-uniform {
  margin-top: -0.75rem;
}

.row.gtr-50.gtr-uniform > * {
  padding-top: 0.75rem;
}

.row {
  margin-top: 0;
  margin-left: -1.5rem;
}

.row > * {
  padding: 0 0 0 1.5rem;
}

.row.gtr-uniform {
  margin-top: -1.5rem;
}

.row.gtr-uniform > * {
  padding-top: 1.5rem;
}

.row.gtr-150 {
  margin-top: 0;
  margin-left: -2.25rem;
}

.row.gtr-150 > * {
  padding: 0 0 0 2.25rem;
}

.row.gtr-150.gtr-uniform {
  margin-top: -2.25rem;
}

.row.gtr-150.gtr-uniform > * {
  padding-top: 2.25rem;
}

.row.gtr-200 {
  margin-top: 0;
  margin-left: -3rem;
}

.row.gtr-200 > * {
  padding: 0 0 0 3rem;
}

.row.gtr-200.gtr-uniform {
  margin-top: -3rem;
}

.row.gtr-200.gtr-uniform > * {
  padding-top: 3rem;
}

@media screen and (max-width: 1680px) {
  .row {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: stretch;
  }

  .row > * {
    box-sizing: border-box;
  }

  .row.gtr-uniform > * > :last-child {
    margin-bottom: 0;
  }

  .row.aln-left {
    justify-content: flex-start;
  }

  .row.aln-center {
    justify-content: center;
  }

  .row.aln-right {
    justify-content: flex-end;
  }

  .row.aln-top {
    align-items: flex-start;
  }

  .row.aln-middle {
    align-items: center;
  }

  .row.aln-bottom {
    align-items: flex-end;
  }

  .row > .imp-xlarge {
    order: -1;
  }

  .row > .col-1-xlarge {
    width: 8.3333333333%;
  }

  .row > .off-1-xlarge {
    margin-left: 8.3333333333%;
  }

  .row > .col-2-xlarge {
    width: 16.6666666667%;
  }

  .row > .off-2-xlarge {
    margin-left: 16.6666666667%;
  }

  .row > .col-3-xlarge {
    width: 25%;
  }

  .row > .off-3-xlarge {
    margin-left: 25%;
  }

  .row > .col-4-xlarge {
    width: 33.3333333333%;
  }

  .row > .off-4-xlarge {
    margin-left: 33.3333333333%;
  }

  .row > .col-5-xlarge {
    width: 41.6666666667%;
  }

  .row > .off-5-xlarge {
    margin-left: 41.6666666667%;
  }

  .row > .col-6-xlarge {
    width: 50%;
  }

  .row > .off-6-xlarge {
    margin-left: 50%;
  }

  .row > .col-7-xlarge {
    width: 58.3333333333%;
  }

  .row > .off-7-xlarge {
    margin-left: 58.3333333333%;
  }

  .row > .col-8-xlarge {
    width: 66.6666666667%;
  }

  .row > .off-8-xlarge {
    margin-left: 66.6666666667%;
  }

  .row > .col-9-xlarge {
    width: 75%;
  }

  .row > .off-9-xlarge {
    margin-left: 75%;
  }

  .row > .col-10-xlarge {
    width: 83.3333333333%;
  }

  .row > .off-10-xlarge {
    margin-left: 83.3333333333%;
  }

  .row > .col-11-xlarge {
    width: 91.6666666667%;
  }

  .row > .off-11-xlarge {
    margin-left: 91.6666666667%;
  }

  .row > .col-12-xlarge {
    width: 100%;
  }

  .row > .off-12-xlarge {
    margin-left: 100%;
  }

  .row.gtr-0 {
    margin-top: 0;
    margin-left: 0rem;
  }

  .row.gtr-0 > * {
    padding: 0 0 0 0rem;
  }

  .row.gtr-0.gtr-uniform {
    margin-top: 0rem;
  }

  .row.gtr-0.gtr-uniform > * {
    padding-top: 0rem;
  }

  .row.gtr-25 {
    margin-top: 0;
    margin-left: -0.375rem;
  }

  .row.gtr-25 > * {
    padding: 0 0 0 0.375rem;
  }

  .row.gtr-25.gtr-uniform {
    margin-top: -0.375rem;
  }

  .row.gtr-25.gtr-uniform > * {
    padding-top: 0.375rem;
  }

  .row.gtr-50 {
    margin-top: 0;
    margin-left: -0.75rem;
  }

  .row.gtr-50 > * {
    padding: 0 0 0 0.75rem;
  }

  .row.gtr-50.gtr-uniform {
    margin-top: -0.75rem;
  }

  .row.gtr-50.gtr-uniform > * {
    padding-top: 0.75rem;
  }

  .row {
    margin-top: 0;
    margin-left: -1.5rem;
  }

  .row > * {
    padding: 0 0 0 1.5rem;
  }

  .row.gtr-uniform {
    margin-top: -1.5rem;
  }

  .row.gtr-uniform > * {
    padding-top: 1.5rem;
  }

  .row.gtr-150 {
    margin-top: 0;
    margin-left: -2.25rem;
  }

  .row.gtr-150 > * {
    padding: 0 0 0 2.25rem;
  }

  .row.gtr-150.gtr-uniform {
    margin-top: -2.25rem;
  }

  .row.gtr-150.gtr-uniform > * {
    padding-top: 2.25rem;
  }

  .row.gtr-200 {
    margin-top: 0;
    margin-left: -3rem;
  }

  .row.gtr-200 > * {
    padding: 0 0 0 3rem;
  }

  .row.gtr-200.gtr-uniform {
    margin-top: -3rem;
  }

  .row.gtr-200.gtr-uniform > * {
    padding-top: 3rem;
  }
}

@media screen and (max-width: 1280px) {
  .row {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: stretch;
  }

  .row > * {
    box-sizing: border-box;
  }

  .row.gtr-uniform > * > :last-child {
    margin-bottom: 0;
  }

  .row.aln-left {
    justify-content: flex-start;
  }

  .row.aln-center {
    justify-content: center;
  }

  .row.aln-right {
    justify-content: flex-end;
  }

  .row.aln-top {
    align-items: flex-start;
  }

  .row.aln-middle {
    align-items: center;
  }

  .row.aln-bottom {
    align-items: flex-end;
  }

  .row > .imp-large {
    order: -1;
  }

  .row > .col-1-large {
    width: 8.3333333333%;
  }

  .row > .off-1-large {
    margin-left: 8.3333333333%;
  }

  .row > .col-2-large {
    width: 16.6666666667%;
  }

  .row > .off-2-large {
    margin-left: 16.6666666667%;
  }

  .row > .col-3-large {
    width: 25%;
  }

  .row > .off-3-large {
    margin-left: 25%;
  }

  .row > .col-4-large {
    width: 33.3333333333%;
  }

  .row > .off-4-large {
    margin-left: 33.3333333333%;
  }

  .row > .col-5-large {
    width: 41.6666666667%;
  }

  .row > .off-5-large {
    margin-left: 41.6666666667%;
  }

  .row > .col-6-large {
    width: 50%;
  }

  .row > .off-6-large {
    margin-left: 50%;
  }

  .row > .col-7-large {
    width: 58.3333333333%;
  }

  .row > .off-7-large {
    margin-left: 58.3333333333%;
  }

  .row > .col-8-large {
    width: 66.6666666667%;
  }

  .row > .off-8-large {
    margin-left: 66.6666666667%;
  }

  .row > .col-9-large {
    width: 75%;
  }

  .row > .off-9-large {
    margin-left: 75%;
  }

  .row > .col-10-large {
    width: 83.3333333333%;
  }

  .row > .off-10-large {
    margin-left: 83.3333333333%;
  }

  .row > .col-11-large {
    width: 91.6666666667%;
  }

  .row > .off-11-large {
    margin-left: 91.6666666667%;
  }

  .row > .col-12-large {
    width: 100%;
  }

  .row > .off-12-large {
    margin-left: 100%;
  }

  .row.gtr-0 {
    margin-top: 0;
    margin-left: 0rem;
  }

  .row.gtr-0 > * {
    padding: 0 0 0 0rem;
  }

  .row.gtr-0.gtr-uniform {
    margin-top: 0rem;
  }

  .row.gtr-0.gtr-uniform > * {
    padding-top: 0rem;
  }

  .row.gtr-25 {
    margin-top: 0;
    margin-left: -0.375rem;
  }

  .row.gtr-25 > * {
    padding: 0 0 0 0.375rem;
  }

  .row.gtr-25.gtr-uniform {
    margin-top: -0.375rem;
  }

  .row.gtr-25.gtr-uniform > * {
    padding-top: 0.375rem;
  }

  .row.gtr-50 {
    margin-top: 0;
    margin-left: -0.75rem;
  }

  .row.gtr-50 > * {
    padding: 0 0 0 0.75rem;
  }

  .row.gtr-50.gtr-uniform {
    margin-top: -0.75rem;
  }

  .row.gtr-50.gtr-uniform > * {
    padding-top: 0.75rem;
  }

  .row {
    margin-top: 0;
    margin-left: -1.5rem;
  }

  .row > * {
    padding: 0 0 0 1.5rem;
  }

  .row.gtr-uniform {
    margin-top: -1.5rem;
  }

  .row.gtr-uniform > * {
    padding-top: 1.5rem;
  }

  .row.gtr-150 {
    margin-top: 0;
    margin-left: -2.25rem;
  }

  .row.gtr-150 > * {
    padding: 0 0 0 2.25rem;
  }

  .row.gtr-150.gtr-uniform {
    margin-top: -2.25rem;
  }

  .row.gtr-150.gtr-uniform > * {
    padding-top: 2.25rem;
  }

  .row.gtr-200 {
    margin-top: 0;
    margin-left: -3rem;
  }

  .row.gtr-200 > * {
    padding: 0 0 0 3rem;
  }

  .row.gtr-200.gtr-uniform {
    margin-top: -3rem;
  }

  .row.gtr-200.gtr-uniform > * {
    padding-top: 3rem;
  }
}

@media screen and (max-width: 980px) {
  .row {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: stretch;
  }

  .row > * {
    box-sizing: border-box;
  }

  .row.gtr-uniform > * > :last-child {
    margin-bottom: 0;
  }

  .row.aln-left {
    justify-content: flex-start;
  }

  .row.aln-center {
    justify-content: center;
  }

  .row.aln-right {
    justify-content: flex-end;
  }

  .row.aln-top {
    align-items: flex-start;
  }

  .row.aln-middle {
    align-items: center;
  }

  .row.aln-bottom {
    align-items: flex-end;
  }

  .row > .imp-medium {
    order: -1;
  }

  .row > .col-1-medium {
    width: 8.3333333333%;
  }

  .row > .off-1-medium {
    margin-left: 8.3333333333%;
  }

  .row > .col-2-medium {
    width: 16.6666666667%;
  }

  .row > .off-2-medium {
    margin-left: 16.6666666667%;
  }

  .row > .col-3-medium {
    width: 25%;
  }

  .row > .off-3-medium {
    margin-left: 25%;
  }

  .row > .col-4-medium {
    width: 33.3333333333%;
  }

  .row > .off-4-medium {
    margin-left: 33.3333333333%;
  }

  .row > .col-5-medium {
    width: 41.6666666667%;
  }

  .row > .off-5-medium {
    margin-left: 41.6666666667%;
  }

  .row > .col-6-medium {
    width: 50%;
  }

  .row > .off-6-medium {
    margin-left: 50%;
  }

  .row > .col-7-medium {
    width: 58.3333333333%;
  }

  .row > .off-7-medium {
    margin-left: 58.3333333333%;
  }

  .row > .col-8-medium {
    width: 66.6666666667%;
  }

  .row > .off-8-medium {
    margin-left: 66.6666666667%;
  }

  .row > .col-9-medium {
    width: 75%;
  }

  .row > .off-9-medium {
    margin-left: 75%;
  }

  .row > .col-10-medium {
    width: 83.3333333333%;
  }

  .row > .off-10-medium {
    margin-left: 83.3333333333%;
  }

  .row > .col-11-medium {
    width: 91.6666666667%;
  }

  .row > .off-11-medium {
    margin-left: 91.6666666667%;
  }

  .row > .col-12-medium {
    width: 100%;
  }

  .row > .off-12-medium {
    margin-left: 100%;
  }

  .row.gtr-0 {
    margin-top: 0;
    margin-left: 0rem;
  }

  .row.gtr-0 > * {
    padding: 0 0 0 0rem;
  }

  .row.gtr-0.gtr-uniform {
    margin-top: 0rem;
  }

  .row.gtr-0.gtr-uniform > * {
    padding-top: 0rem;
  }

  .row.gtr-25 {
    margin-top: 0;
    margin-left: -0.375rem;
  }

  .row.gtr-25 > * {
    padding: 0 0 0 0.375rem;
  }

  .row.gtr-25.gtr-uniform {
    margin-top: -0.375rem;
  }

  .row.gtr-25.gtr-uniform > * {
    padding-top: 0.375rem;
  }

  .row.gtr-50 {
    margin-top: 0;
    margin-left: -0.75rem;
  }

  .row.gtr-50 > * {
    padding: 0 0 0 0.75rem;
  }

  .row.gtr-50.gtr-uniform {
    margin-top: -0.75rem;
  }

  .row.gtr-50.gtr-uniform > * {
    padding-top: 0.75rem;
  }

  .row {
    margin-top: 0;
    margin-left: -1.5rem;
  }

  .row > * {
    padding: 0 0 0 1.5rem;
  }

  .row.gtr-uniform {
    margin-top: -1.5rem;
  }

  .row.gtr-uniform > * {
    padding-top: 1.5rem;
  }

  .row.gtr-150 {
    margin-top: 0;
    margin-left: -2.25rem;
  }

  .row.gtr-150 > * {
    padding: 0 0 0 2.25rem;
  }

  .row.gtr-150.gtr-uniform {
    margin-top: -2.25rem;
  }

  .row.gtr-150.gtr-uniform > * {
    padding-top: 2.25rem;
  }

  .row.gtr-200 {
    margin-top: 0;
    margin-left: -3rem;
  }

  .row.gtr-200 > * {
    padding: 0 0 0 3rem;
  }

  .row.gtr-200.gtr-uniform {
    margin-top: -3rem;
  }

  .row.gtr-200.gtr-uniform > * {
    padding-top: 3rem;
  }
}

@media screen and (max-width: 736px) {
  .row {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: stretch;
  }

  .row > * {
    box-sizing: border-box;
  }

  .row.gtr-uniform > * > :last-child {
    margin-bottom: 0;
  }

  .row.aln-left {
    justify-content: flex-start;
  }

  .row.aln-center {
    justify-content: center;
  }

  .row.aln-right {
    justify-content: flex-end;
  }

  .row.aln-top {
    align-items: flex-start;
  }

  .row.aln-middle {
    align-items: center;
  }

  .row.aln-bottom {
    align-items: flex-end;
  }

  .row > .imp-small {
    order: -1;
  }

  .row > .col-1-small {
    width: 8.3333333333%;
  }

  .row > .off-1-small {
    margin-left: 8.3333333333%;
  }

  .row > .col-2-small {
    width: 16.6666666667%;
  }

  .row > .off-2-small {
    margin-left: 16.6666666667%;
  }

  .row > .col-3-small {
    width: 25%;
  }

  .row > .off-3-small {
    margin-left: 25%;
  }

  .row > .col-4-small {
    width: 33.3333333333%;
  }

  .row > .off-4-small {
    margin-left: 33.3333333333%;
  }

  .row > .col-5-small {
    width: 41.6666666667%;
  }

  .row > .off-5-small {
    margin-left: 41.6666666667%;
  }

  .row > .col-6-small {
    width: 50%;
  }

  .row > .off-6-small {
    margin-left: 50%;
  }

  .row > .col-7-small {
    width: 58.3333333333%;
  }

  .row > .off-7-small {
    margin-left: 58.3333333333%;
  }

  .row > .col-8-small {
    width: 66.6666666667%;
  }

  .row > .off-8-small {
    margin-left: 66.6666666667%;
  }

  .row > .col-9-small {
    width: 75%;
  }

  .row > .off-9-small {
    margin-left: 75%;
  }

  .row > .col-10-small {
    width: 83.3333333333%;
  }

  .row > .off-10-small {
    margin-left: 83.3333333333%;
  }

  .row > .col-11-small {
    width: 91.6666666667%;
  }

  .row > .off-11-small {
    margin-left: 91.6666666667%;
  }

  .row > .col-12-small {
    width: 100%;
  }

  .row > .off-12-small {
    margin-left: 100%;
  }

  .row.gtr-0 {
    margin-top: 0;
    margin-left: 0rem;
  }

  .row.gtr-0 > * {
    padding: 0 0 0 0rem;
  }

  .row.gtr-0.gtr-uniform {
    margin-top: 0rem;
  }

  .row.gtr-0.gtr-uniform > * {
    padding-top: 0rem;
  }

  .row.gtr-25 {
    margin-top: 0;
    margin-left: -0.375rem;
  }

  .row.gtr-25 > * {
    padding: 0 0 0 0.375rem;
  }

  .row.gtr-25.gtr-uniform {
    margin-top: -0.375rem;
  }

  .row.gtr-25.gtr-uniform > * {
    padding-top: 0.375rem;
  }

  .row.gtr-50 {
    margin-top: 0;
    margin-left: -0.75rem;
  }

  .row.gtr-50 > * {
    padding: 0 0 0 0.75rem;
  }

  .row.gtr-50.gtr-uniform {
    margin-top: -0.75rem;
  }

  .row.gtr-50.gtr-uniform > * {
    padding-top: 0.75rem;
  }

  .row {
    margin-top: 0;
    margin-left: -1.5rem;
  }

  .row > * {
    padding: 0 0 0 1.5rem;
  }

  .row.gtr-uniform {
    margin-top: -1.5rem;
  }

  .row.gtr-uniform > * {
    padding-top: 1.5rem;
  }

  .row.gtr-150 {
    margin-top: 0;
    margin-left: -2.25rem;
  }

  .row.gtr-150 > * {
    padding: 0 0 0 2.25rem;
  }

  .row.gtr-150.gtr-uniform {
    margin-top: -2.25rem;
  }

  .row.gtr-150.gtr-uniform > * {
    padding-top: 2.25rem;
  }

  .row.gtr-200 {
    margin-top: 0;
    margin-left: -3rem;
  }

  .row.gtr-200 > * {
    padding: 0 0 0 3rem;
  }

  .row.gtr-200.gtr-uniform {
    margin-top: -3rem;
  }

  .row.gtr-200.gtr-uniform > * {
    padding-top: 3rem;
  }
}

@media screen and (max-width: 480px) {
  .row {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: stretch;
  }

  .row > * {
    box-sizing: border-box;
  }

  .row.gtr-uniform > * > :last-child {
    margin-bottom: 0;
  }

  .row.aln-left {
    justify-content: flex-start;
  }

  .row.aln-center {
    justify-content: center;
  }

  .row.aln-right {
    justify-content: flex-end;
  }

  .row.aln-top {
    align-items: flex-start;
  }

  .row.aln-middle {
    align-items: center;
  }

  .row.aln-bottom {
    align-items: flex-end;
  }

  .row > .imp-xsmall {
    order: -1;
  }

  .row > .col-1-xsmall {
    width: 8.3333333333%;
  }

  .row > .off-1-xsmall {
    margin-left: 8.3333333333%;
  }

  .row > .col-2-xsmall {
    width: 16.6666666667%;
  }

  .row > .off-2-xsmall {
    margin-left: 16.6666666667%;
  }

  .row > .col-3-xsmall {
    width: 25%;
  }

  .row > .off-3-xsmall {
    margin-left: 25%;
  }

  .row > .col-4-xsmall {
    width: 33.3333333333%;
  }

  .row > .off-4-xsmall {
    margin-left: 33.3333333333%;
  }

  .row > .col-5-xsmall {
    width: 41.6666666667%;
  }

  .row > .off-5-xsmall {
    margin-left: 41.6666666667%;
  }

  .row > .col-6-xsmall {
    width: 50%;
  }

  .row > .off-6-xsmall {
    margin-left: 50%;
  }

  .row > .col-7-xsmall {
    width: 58.3333333333%;
  }

  .row > .off-7-xsmall {
    margin-left: 58.3333333333%;
  }

  .row > .col-8-xsmall {
    width: 66.6666666667%;
  }

  .row > .off-8-xsmall {
    margin-left: 66.6666666667%;
  }

  .row > .col-9-xsmall {
    width: 75%;
  }

  .row > .off-9-xsmall {
    margin-left: 75%;
  }

  .row > .col-10-xsmall {
    width: 83.3333333333%;
  }

  .row > .off-10-xsmall {
    margin-left: 83.3333333333%;
  }

  .row > .col-11-xsmall {
    width: 91.6666666667%;
  }

  .row > .off-11-xsmall {
    margin-left: 91.6666666667%;
  }

  .row > .col-12-xsmall {
    width: 100%;
  }

  .row > .off-12-xsmall {
    margin-left: 100%;
  }

  .row.gtr-0 {
    margin-top: 0;
    margin-left: 0rem;
  }

  .row.gtr-0 > * {
    padding: 0 0 0 0rem;
  }

  .row.gtr-0.gtr-uniform {
    margin-top: 0rem;
  }

  .row.gtr-0.gtr-uniform > * {
    padding-top: 0rem;
  }

  .row.gtr-25 {
    margin-top: 0;
    margin-left: -0.375rem;
  }

  .row.gtr-25 > * {
    padding: 0 0 0 0.375rem;
  }

  .row.gtr-25.gtr-uniform {
    margin-top: -0.375rem;
  }

  .row.gtr-25.gtr-uniform > * {
    padding-top: 0.375rem;
  }

  .row.gtr-50 {
    margin-top: 0;
    margin-left: -0.75rem;
  }

  .row.gtr-50 > * {
    padding: 0 0 0 0.75rem;
  }

  .row.gtr-50.gtr-uniform {
    margin-top: -0.75rem;
  }

  .row.gtr-50.gtr-uniform > * {
    padding-top: 0.75rem;
  }

  .row {
    margin-top: 0;
    margin-left: -1.5rem;
  }

  .row > * {
    padding: 0 0 0 1.5rem;
  }

  .row.gtr-uniform {
    margin-top: -1.5rem;
  }

  .row.gtr-uniform > * {
    padding-top: 1.5rem;
  }

  .row.gtr-150 {
    margin-top: 0;
    margin-left: -2.25rem;
  }

  .row.gtr-150 > * {
    padding: 0 0 0 2.25rem;
  }

  .row.gtr-150.gtr-uniform {
    margin-top: -2.25rem;
  }

  .row.gtr-150.gtr-uniform > * {
    padding-top: 2.25rem;
  }

  .row.gtr-200 {
    margin-top: 0;
    margin-left: -3rem;
  }

  .row.gtr-200 > * {
    padding: 0 0 0 3rem;
  }

  .row.gtr-200.gtr-uniform {
    margin-top: -3rem;
  }

  .row.gtr-200.gtr-uniform > * {
    padding-top: 3rem;
  }
}

@media screen and (max-width: 360px) {
  .row {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: stretch;
  }

  .row > * {
    box-sizing: border-box;
  }

  .row.gtr-uniform > * > :last-child {
    margin-bottom: 0;
  }

  .row.aln-left {
    justify-content: flex-start;
  }

  .row.aln-center {
    justify-content: center;
  }

  .row.aln-right {
    justify-content: flex-end;
  }

  .row.aln-top {
    align-items: flex-start;
  }

  .row.aln-middle {
    align-items: center;
  }

  .row.aln-bottom {
    align-items: flex-end;
  }

  .row > .imp-xxsmall {
    order: -1;
  }

  .row > .col-1-xxsmall {
    width: 8.3333333333%;
  }

  .row > .off-1-xxsmall {
    margin-left: 8.3333333333%;
  }

  .row > .col-2-xxsmall {
    width: 16.6666666667%;
  }

  .row > .off-2-xxsmall {
    margin-left: 16.6666666667%;
  }

  .row > .col-3-xxsmall {
    width: 25%;
  }

  .row > .off-3-xxsmall {
    margin-left: 25%;
  }

  .row > .col-4-xxsmall {
    width: 33.3333333333%;
  }

  .row > .off-4-xxsmall {
    margin-left: 33.3333333333%;
  }

  .row > .col-5-xxsmall {
    width: 41.6666666667%;
  }

  .row > .off-5-xxsmall {
    margin-left: 41.6666666667%;
  }

  .row > .col-6-xxsmall {
    width: 50%;
  }

  .row > .off-6-xxsmall {
    margin-left: 50%;
  }

  .row > .col-7-xxsmall {
    width: 58.3333333333%;
  }

  .row > .off-7-xxsmall {
    margin-left: 58.3333333333%;
  }

  .row > .col-8-xxsmall {
    width: 66.6666666667%;
  }

  .row > .off-8-xxsmall {
    margin-left: 66.6666666667%;
  }

  .row > .col-9-xxsmall {
    width: 75%;
  }

  .row > .off-9-xxsmall {
    margin-left: 75%;
  }

  .row > .col-10-xxsmall {
    width: 83.3333333333%;
  }

  .row > .off-10-xxsmall {
    margin-left: 83.3333333333%;
  }

  .row > .col-11-xxsmall {
    width: 91.6666666667%;
  }

  .row > .off-11-xxsmall {
    margin-left: 91.6666666667%;
  }

  .row > .col-12-xxsmall {
    width: 100%;
  }

  .row > .off-12-xxsmall {
    margin-left: 100%;
  }

  .row.gtr-0 {
    margin-top: 0;
    margin-left: 0rem;
  }

  .row.gtr-0 > * {
    padding: 0 0 0 0rem;
  }

  .row.gtr-0.gtr-uniform {
    margin-top: 0rem;
  }

  .row.gtr-0.gtr-uniform > * {
    padding-top: 0rem;
  }

  .row.gtr-25 {
    margin-top: 0;
    margin-left: -0.375rem;
  }

  .row.gtr-25 > * {
    padding: 0 0 0 0.375rem;
  }

  .row.gtr-25.gtr-uniform {
    margin-top: -0.375rem;
  }

  .row.gtr-25.gtr-uniform > * {
    padding-top: 0.375rem;
  }

  .row.gtr-50 {
    margin-top: 0;
    margin-left: -0.75rem;
  }

  .row.gtr-50 > * {
    padding: 0 0 0 0.75rem;
  }

  .row.gtr-50.gtr-uniform {
    margin-top: -0.75rem;
  }

  .row.gtr-50.gtr-uniform > * {
    padding-top: 0.75rem;
  }

  .row {
    margin-top: 0;
    margin-left: -1.5rem;
  }

  .row > * {
    padding: 0 0 0 1.5rem;
  }

  .row.gtr-uniform {
    margin-top: -1.5rem;
  }

  .row.gtr-uniform > * {
    padding-top: 1.5rem;
  }

  .row.gtr-150 {
    margin-top: 0;
    margin-left: -2.25rem;
  }

  .row.gtr-150 > * {
    padding: 0 0 0 2.25rem;
  }

  .row.gtr-150.gtr-uniform {
    margin-top: -2.25rem;
  }

  .row.gtr-150.gtr-uniform > * {
    padding-top: 2.25rem;
  }

  .row.gtr-200 {
    margin-top: 0;
    margin-left: -3rem;
  }

  .row.gtr-200 > * {
    padding: 0 0 0 3rem;
  }

  .row.gtr-200.gtr-uniform {
    margin-top: -3rem;
  }

  .row.gtr-200.gtr-uniform > * {
    padding-top: 3rem;
  }
}

/* Box */

.box {
  border-radius: 4px;
  border: solid 1px;
  margin-bottom: 2rem;
  padding: 1.5rem;
}

.box > :last-child,
.box > :last-child > :last-child,
.box > :last-child > :last-child > :last-child {
  margin-bottom: 0;
}

.box.alt {
  border: 0;
  border-radius: 0;
  padding: 0;
}

.box {
  border-color: rgba(143, 143, 143, 0.25);
}

/* Button */

input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.button {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  border-radius: 4px;
  border: 0;
  cursor: pointer;
  display: inline-block;
  font-family: "Raleway", Helvetica, sans-serif;
  font-weight: 800;
  font-size: 0.75rem;
  height: 3rem;
  line-height: 3.075rem;
  padding: 0 2.5rem;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 0.025rem;
}

input[type="submit"].next,
input[type="reset"].next,
input[type="button"].next,
button.next,
.button.next {
  text-decoration: none;
}

input[type="submit"].next:before,
input[type="reset"].next:before,
input[type="button"].next:before,
button.next:before,
.button.next:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}

input[type="submit"].next:before,
input[type="reset"].next:before,
input[type="button"].next:before,
button.next:before,
.button.next:before {
  content: "\f178";
  float: right;
  line-height: inherit;
  margin: 0 -0.25rem 0 0.5rem;
}

input[type="submit"].icon:before,
input[type="reset"].icon:before,
input[type="button"].icon:before,
button.icon:before,
.button.icon:before {
  margin-right: 0.5rem;
  margin-left: -0.25rem;
}

input[type="submit"].fit,
input[type="reset"].fit,
input[type="button"].fit,
button.fit,
.button.fit {
  width: 100%;
}

input[type="submit"].small,
input[type="reset"].small,
input[type="button"].small,
button.small,
.button.small {
  font-size: 0.6rem;
  height: 2.25rem;
  line-height: 2.30625rem;
  padding: 0 1.5rem;
}

input[type="submit"].large,
input[type="reset"].large,
input[type="button"].large,
button.large,
.button.large {
  font-size: 1rem;
  height: 3.75rem;
  line-height: 3.84375rem;
  padding: 0 2.5rem;
}

input[type="submit"].disabled,
input[type="submit"]:disabled,
input[type="reset"].disabled,
input[type="reset"]:disabled,
input[type="button"].disabled,
input[type="button"]:disabled,
button.disabled,
button:disabled,
.button.disabled,
.button:disabled {
  pointer-events: none;
  opacity: 0.25;
}

input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.button {
  background-color: var(--main-color-hex);
  color: #ffffff !important;
}

input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
button:hover,
.button:hover {
  background-color: var(--main-color-hex);
  color: #ffffff !important;
}

input[type="submit"]:active,
input[type="reset"]:active,
input[type="button"]:active,
button:active,
.button:active {
  background-color: #348bde;
}

input[type="submit"].alt,
input[type="reset"].alt,
input[type="button"].alt,
button.alt,
.button.alt {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px rgba(143, 143, 143, 0.25);
  color: #3f3f3f !important;
}

input[type="submit"].alt:hover,
input[type="reset"].alt:hover,
input[type="button"].alt:hover,
button.alt:hover,
.button.alt:hover {
  background-color: rgba(143, 143, 143, 0.075);
}

input[type="submit"].alt.icon:before,
input[type="reset"].alt.icon:before,
input[type="button"].alt.icon:before,
button.alt.icon:before,
.button.alt.icon:before {
  color: #bfbfbf;
}

/* Form */

form {
  margin: 0 0 2rem 0;
}

form > :last-child {
  margin-bottom: 0;
}

form > .fields {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: calc(100% + 3rem);
  margin: -1.5rem 0 2rem -1.5rem;
}

form > .fields > .field {
  -moz-flex-grow: 0;
  -webkit-flex-grow: 0;
  -ms-flex-grow: 0;
  flex-grow: 0;
  -moz-flex-shrink: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
  padding: 1.5rem 0 0 1.5rem;
  width: calc(100% - 1.5rem);
}

form > .fields > .field.half {
  width: calc(50% - 0.75rem);
}

form > .fields > .field.third {
  width: calc(100% / 3 - 0.5rem);
}

form > .fields > .field.quarter {
  width: calc(25% - 0.375rem);
}

@media screen and (max-width: 480px) {
  form > .fields {
    width: calc(100% + 3rem);
    margin: -1.5rem 0 2rem -1.5rem;
  }

  form > .fields > .field {
    padding: 1.5rem 0 0 1.5rem;
    width: calc(100% - 1.5rem);
  }

  form > .fields > .field.half {
    width: calc(100% - 1.5rem);
  }

  form > .fields > .field.third {
    width: calc(100% - 1.5rem);
  }

  form > .fields > .field.quarter {
    width: calc(100% - 1.5rem);
  }
}

label {
  display: block;
  font-size: 0.9rem;
  font-weight: 600;
  margin: 0 0 1rem 0;
}

input[type="text"],
input[type="password"],
input[type="email"],
select,
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  border-radius: 4px;
  border: none;
  border: solid 1px;
  color: inherit;
  display: block;
  outline: 0;
  padding: 0 1rem;
  text-decoration: none;
  width: 100%;
}

input[type="text"]:invalid,
input[type="password"]:invalid,
input[type="email"]:invalid,
select:invalid,
textarea:invalid {
  box-shadow: none;
}

select {
  background-size: 1.25rem;
  background-repeat: no-repeat;
  background-position: calc(100% - 1rem) center;
  height: 3rem;
  padding-right: 3rem;
  text-overflow: ellipsis;
}

select:focus::-ms-value {
  background-color: transparent;
}

select::-ms-expand {
  display: none;
}

input[type="text"],
input[type="password"],
input[type="email"],
select {
  height: 3rem;
}

textarea {
  padding: 0.75rem 1rem;
}

input[type="checkbox"],
input[type="radio"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  display: block;
  float: left;
  margin-right: -2rem;
  opacity: 0;
  width: 1rem;
  z-index: -1;
}

input[type="checkbox"] + label,
input[type="radio"] + label {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: 300;
  padding-left: 2.55rem;
  padding-right: 0.75rem;
  position: relative;
}

input[type="checkbox"] + label:before,
input[type="radio"] + label:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}

input[type="checkbox"] + label:before,
input[type="radio"] + label:before {
  border-radius: 4px;
  border: solid 1px;
  content: "";
  display: inline-block;
  height: 1.8rem;
  left: 0;
  line-height: 1.725rem;
  position: absolute;
  text-align: center;
  top: 0;
  width: 1.8rem;
}

input[type="checkbox"]:checked + label:before,
input[type="radio"]:checked + label:before {
  content: "\f00c";
}

input[type="checkbox"] + label:before {
  border-radius: 4px;
}

input[type="radio"] + label:before {
  border-radius: 100%;
}

::-webkit-input-placeholder {
  opacity: 1;
}

:-moz-placeholder {
  opacity: 1;
}

::-moz-placeholder {
  opacity: 1;
}

:-ms-input-placeholder {
  opacity: 1;
}

label {
  color: #3f3f3f;
}

input[type="text"],
input[type="password"],
input[type="email"],
select,
textarea {
  border-color: rgba(143, 143, 143, 0.25);
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
select:focus,
textarea:focus {
  border-color: var(--main-color-hex);
  box-shadow: 0 0 0 1px var(--main-color-hex);
}

select {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'%3E%3Cpath d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='rgba(143, 143, 143, 0.25)' /%3E%3C/svg%3E");
}

select option {
  color: #3f3f3f;
  background: #ffffff;
}

input[type="checkbox"] + label,
input[type="radio"] + label {
  color: #3f3f3f;
}

input[type="checkbox"] + label:before,
input[type="radio"] + label:before {
  border-color: rgba(143, 143, 143, 0.25);
}

input[type="checkbox"]:checked + label:before,
input[type="radio"]:checked + label:before {
  background-color: var(--main-color-hex);
  border-color: var(--main-color-hex);
  box-shadow: 0 0 0 1px var(--main-color-hex);
  color: #ffffff;
}

input[type="checkbox"]:focus + label:before,
input[type="radio"]:focus + label:before {
  border-color: var(--main-color-hex);
  box-shadow: 0 0 0 1px var(--main-color-hex);
}

::-webkit-input-placeholder {
  color: #bfbfbf !important;
}

:-moz-placeholder {
  color: #bfbfbf !important;
}

::-moz-placeholder {
  color: #bfbfbf !important;
}

:-ms-input-placeholder {
  color: #bfbfbf !important;
}

.formerize-placeholder {
  color: #bfbfbf !important;
}

/* Icon */

.icon {
  text-decoration: none;
  border-bottom: none;
  position: relative;
}

.icon:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}

.icon > .label {
  display: none;
}

/* Image */

.image {
  display: inline-block;
  position: relative;
  border-radius: 4px;
  border: 0;
}

.image[data-position] img {
  -moz-object-fit: cover;
  -webkit-object-fit: cover;
  -ms-object-fit: cover;
  object-fit: cover;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image[data-position="top left"] img {
  -moz-object-position: top left;
  -webkit-object-position: top left;
  -ms-object-position: top left;
  object-position: top left;
}

.image[data-position="top"] img {
  -moz-object-position: top;
  -webkit-object-position: top;
  -ms-object-position: top;
  object-position: top;
}

.image[data-position="top right"] img {
  -moz-object-position: top right;
  -webkit-object-position: top right;
  -ms-object-position: top right;
  object-position: top right;
}

.image[data-position="right"] img {
  -moz-object-position: right;
  -webkit-object-position: right;
  -ms-object-position: right;
  object-position: right;
}

.image[data-position="bottom right"] img {
  -moz-object-position: bottom right;
  -webkit-object-position: bottom right;
  -ms-object-position: bottom right;
  object-position: bottom right;
}

.image[data-position="bottom"] img {
  -moz-object-position: bottom;
  -webkit-object-position: bottom;
  -ms-object-position: bottom;
  object-position: bottom;
}

.image[data-position="bottom left"] img {
  -moz-object-position: bottom left;
  -webkit-object-position: bottom left;
  -ms-object-position: bottom left;
  object-position: bottom left;
}

.image[data-position="left"] img {
  -moz-object-position: left;
  -webkit-object-position: left;
  -ms-object-position: left;
  object-position: left;
}

.image[data-position="center"] img {
  -moz-object-position: center;
  -webkit-object-position: center;
  -ms-object-position: center;
  object-position: center;
}

.image[data-position="25% 25%"] img {
  -moz-object-position: 25% 25%;
  -webkit-object-position: 25% 25%;
  -ms-object-position: 25% 25%;
  object-position: 25% 25%;
}

.image[data-position="75% 25%"] img {
  -moz-object-position: 75% 25%;
  -webkit-object-position: 75% 25%;
  -ms-object-position: 75% 25%;
  object-position: 75% 25%;
}

.image[data-position="75% 75%"] img {
  -moz-object-position: 75% 75%;
  -webkit-object-position: 75% 75%;
  -ms-object-position: 75% 75%;
  object-position: 75% 75%;
}

.image[data-position="25% 75%"] img {
  -moz-object-position: 25% 75%;
  -webkit-object-position: 25% 75%;
  -ms-object-position: 25% 75%;
  object-position: 25% 75%;
}

.image img {
  display: block;
  border-radius: 4px;
}

.image.left,
.image.right {
  max-width: 40%;
}

.image.left img,
.image.right img {
  width: 100%;
}

.image.left {
  float: left;
  margin: 0 1.5rem 1rem 0;
  top: 0.25rem;
}

.image.right {
  float: right;
  margin: 0 0 1rem 1.5rem;
  top: 0.25rem;
}

.image.fit {
  display: block;
  margin: 0 0 2rem 0;
  width: 100%;
}

.image.fit img {
  width: 100%;
}

.image.main {
  display: block;
  margin: 0 0 3rem 0;
  width: 100%;
}

.image.main img {
  width: 100%;
}

a.image {
  overflow: hidden;
}

a.image img {
  -moz-transition: -moz-transform 0.2s ease;
  -webkit-transition: -webkit-transform 0.2s ease;
  -ms-transition: -ms-transform 0.2s ease;
  transition: transform 0.2s ease;
}

a.image:hover img {
  -moz-transform: scale(1.075);
  -webkit-transform: scale(1.075);
  -ms-transform: scale(1.075);
  transform: scale(1.075);
}

/* List */

ol {
  list-style: decimal;
  margin: 0 0 2rem 0;
  padding-left: 1.25rem;
}

ol li {
  padding-left: 0.25rem;
}

ul {
  list-style: disc;
  margin: 0 0 2rem 0;
  padding-left: 1rem;
}

ul li {
  padding-left: 0.5rem;
}

ul.alt {
  list-style: none;
  padding-left: 0;
}

ul.alt li {
  border-top: solid 1px;
  padding: 0.5rem 0;
}

ul.alt li:first-child {
  border-top: 0;
  padding-top: 0;
}

dl {
  margin: 0 0 2rem 0;
}

dl dt {
  display: block;
  font-weight: 600;
  margin: 0 0 1rem 0;
}

dl dd {
  margin-left: 2rem;
}

ul.alt li {
  border-top-color: rgba(143, 143, 143, 0.25);
}

/* Actions */

ul.actions {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  cursor: default;
  list-style: none;
  margin-left: -1rem;
  padding-left: 0;
}

ul.actions li {
  padding: 0 0 0 1rem;
  vertical-align: middle;
}

ul.actions.special {
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  width: 100%;
  margin-left: 0;
}

ul.actions.special li:first-child {
  padding-left: 0;
}

ul.actions.stacked {
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: 0;
}

ul.actions.stacked li {
  padding: 1.3rem 0 0 0;
}

ul.actions.stacked li:first-child {
  padding-top: 0;
}

ul.actions.fit {
  width: calc(100% + 1rem);
}

ul.actions.fit li {
  -moz-flex-grow: 1;
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  -moz-flex-shrink: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-shrink: 1;
  flex-shrink: 1;
  width: 100%;
}

ul.actions.fit li > * {
  width: 100%;
}

ul.actions.fit.stacked {
  width: 100%;
}

/* Icons */

ul.icons {
  cursor: default;
  list-style: none;
  padding-left: 0;
}

ul.icons li {
  display: inline-block;
  padding: 0 1.25rem 0 0;
}

ul.icons li:last-child {
  padding-right: 0;
}

ul.icons li .icon:before {
  font-size: 1.375rem;
}

/* Faces */

ul.faces {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  list-style: none;
  padding-left: 0;
}

ul.faces li {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  padding-left: 0;
  text-align: center;
  margin: 0 0 0 4rem;
}

ul.faces li:first-child {
  margin-left: 0;
}

ul.faces li > :last-child {
  margin-bottom: 0;
}

ul.faces li .image {
  border-radius: 100%;
  margin: 0 0 1.5rem 0;
}

ul.faces li .image img {
  border-radius: 100%;
  width: 8rem;
}

ul.faces li h3 {
  font-size: 0.8rem;
  margin: 0 0 0.25rem 0;
}

ul.faces li p {
  font-size: 0.8rem;
}

@media screen and (max-width: 1280px) {
  ul.faces li {
    margin: 0 0 0 3rem;
  }
}

@media screen and (max-width: 980px) {
  ul.faces li {
    margin: 0 0 0 2rem;
  }
}

@media screen and (max-width: 736px) {
  ul.faces li .image img {
    width: 6rem;
  }
}

@media screen and (max-width: 480px) {
  ul.faces {
    display: block;
  }

  ul.faces li {
    margin: 0 0 2rem 0;
  }

  ul.faces li .image img {
    width: 10rem;
  }

  ul.faces li h3 {
    font-size: 1rem;
  }

  ul.faces li p {
    font-size: 1rem;
  }

  ul.faces li:last-child {
    margin-bottom: 0;
  }
}

/* Table */

.table-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

table {
  margin: 0 0 2rem 0;
  width: 100%;
}

table tbody tr {
  border: solid 1px;
  border-left: 0;
  border-right: 0;
}

table td {
  padding: 0.75rem 0.75rem;
}

table th {
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0 0.75rem 0.75rem 0.75rem;
  text-align: left;
}

table thead {
  border-bottom: solid 2px;
}

table tfoot {
  border-top: solid 2px;
}

table.alt {
  border-collapse: separate;
}

table.alt tbody tr td {
  border: solid 1px;
  border-left-width: 0;
  border-top-width: 0;
}

table.alt tbody tr td:first-child {
  border-left-width: 1px;
}

table.alt tbody tr:first-child td {
  border-top-width: 1px;
}

table.alt thead {
  border-bottom: 0;
}

table.alt tfoot {
  border-top: 0;
}

table tbody tr {
  border-color: rgba(143, 143, 143, 0.25);
}

table tbody tr:nth-child(2n + 1) {
  background-color: rgba(143, 143, 143, 0.075);
}

table th {
  color: #3f3f3f;
}

table thead {
  border-bottom-color: rgba(143, 143, 143, 0.25);
}

table tfoot {
  border-top-color: rgba(143, 143, 143, 0.25);
}

table.alt tbody tr td {
  border-color: rgba(143, 143, 143, 0.25);
}

/* Post */

.post {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin: 0 0 3.5rem 0;
  min-height: 28rem;
}

.post > .content {
  padding: 5rem 4rem 5rem 0;
  width: 60%;
}

.post > .content header .category {
  font-weight: 600;
  display: block;
  margin: 0 0 0.35rem 0;
}

.post > .content > :last-child {
  margin-bottom: 0;
}

.post > .image {
  -moz-align-self: -moz-stretch;
  -webkit-align-self: -webkit-stretch;
  -ms-align-self: -ms-stretch;
  align-self: stretch;
  width: 40%;
  min-height: inherit;
  border-radius: 4px;
}

.post > .image img {
  border-radius: 4px;
}

.post.alt {
  -moz-flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.post.alt > .content {
  padding: 5rem 0 5rem 4rem;
}

.post.style2 {
  border-radius: 4px;
}

.post.style2 > .content {
  padding: 5rem 4rem;
}

.post.style2 > .image {
  border-radius: 0 4px 4px 0;
}

.post.style2 > .image img {
  border-radius: 0 4px 4px 0;
}

.post.style2.alt {
  -moz-flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.post.style2.alt > .content {
  padding: 5rem 4rem;
}

.post.style2.alt > .image {
  border-radius: 4px 0 0 4px;
}

.post.style2.alt > .image img {
  border-radius: 4px 0 0 4px;
}

@media screen and (max-width: 980px) {
  .post > .content {
    padding: 4rem 3rem 4rem 0;
    width: 50%;
  }

  .post > .image {
    width: 50%;
  }

  .post.alt > .content {
    padding: 4rem 0 4rem 3rem;
  }

  .post.style2 > .content {
    padding: 4rem 3rem;
  }

  .post.style2.alt > .content {
    padding: 4rem 3rem;
  }
}

@media screen and (max-width: 736px) {
  .post > .content {
    padding: 2.5rem 2rem 2.5rem 0;
    width: 50%;
  }

  .post > .image {
    width: 50%;
  }

  .post.alt > .content {
    padding: 2.5rem 0 2.5rem 2rem;
  }

  .post.style2 > .content {
    padding: 2rem;
  }

  .post.style2.alt > .content {
    padding: 2rem;
  }
}

@media screen and (max-width: 480px) {
  .post {
    -moz-flex-direction: column-reverse !important;
    -webkit-flex-direction: column-reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
    border-top: solid 1px;
    padding-top: 2rem;
  }

  .post > .content {
    padding: 0 !important;
    width: 100% !important;
  }

  .post > .image {
    width: 100% !important;
    min-height: 0;
    height: 15rem;
    border-radius: 4px 4px 0 0 !important;
    margin: 0 0 2rem 0;
  }

  .post > .image img {
    border-radius: 4px 4px 0 0 !important;
  }

  .post:first-child {
    border-top: 0;
    padding-top: 0;
  }

  .post.style2 {
    border-top: 0;
    padding-top: 0;
  }

  .post.style2 > .content {
    padding: 2rem !important;
    margin: 0;
  }

  .post.style2 > .image {
    margin-bottom: 0;
  }

  .post.style2.alt > .content {
    padding: 2rem !important;
  }
}

.post > .content header .category {
  color: var(--main-color-hex);
}

.post.style2 {
  background-color: #ffffff;
}

@media screen and (max-width: 480px) {
  .post {
    border-top-color: rgba(143, 143, 143, 0.25);
  }
}

/* Main */

#wrapper > .main {
  padding: 8rem 0 6rem 0;
}

#wrapper > .main > header {
  width: 100%;
  max-width: 35rem;
  text-align: center;
  margin: 0 auto 3rem auto;
}

#wrapper > .main > header:before {
  content: "";
  display: inline-block;
  width: 6.5rem;
  height: 0.25rem;
  margin: 0 0 1.5rem 0;
  border-radius: 4px;
  background-color: var(--main-color-hex);
}

#wrapper > .main > .inner {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 58rem;
}

#wrapper > .main > .inner.narrow {
  max-width: 48rem;
}

#wrapper > .main > .inner.style2 {
  padding: 5rem 4rem 3rem 4rem;
  background-color: #ffffff;
  border-radius: 4px;
}

#wrapper > .main > .inner.style2 > .image.main:first-child {
  position: relative;
  top: -5rem;
  left: -4rem;
  width: calc(100% + 8rem);
  height: 25rem;
  margin-bottom: 0;
  border-radius: 4px 4px 0 0;
}

#wrapper > .main > .inner.style2 > .image.main:first-child img {
  border-radius: 4px 4px 0 0;
}

#wrapper > .main.special {
  text-align: center;
}

#wrapper > .main.accent1 {
  background-color: var(--main-color-hex);
  color: #ffffff;
}

#wrapper > .main.accent1 input,
#wrapper > .main.accent1 select,
#wrapper > .main.accent1 textarea {
  color: #ffffff;
}

#wrapper > .main.accent1 a {
  color: inherit;
}

#wrapper > .main.accent1 a:hover {
  color: var(--main-color-hex) !important;
}

#wrapper > .main.accent1 strong,
#wrapper > .main.accent1 b {
  color: #ffffff;
}

#wrapper > .main.accent1 h1,
#wrapper > .main.accent1 h2,
#wrapper > .main.accent1 h3,
#wrapper > .main.accent1 h4,
#wrapper > .main.accent1 h5,
#wrapper > .main.accent1 h6 {
  color: #ffffff;
}

#wrapper > .main.accent1 blockquote {
  border-left-color: rgba(255, 255, 255, 0.25);
}

#wrapper > .main.accent1 code {
  background: rgba(255, 255, 255, 0.075);
  border-color: rgba(255, 255, 255, 0.25);
}

#wrapper > .main.accent1 hr {
  border-bottom-color: rgba(255, 255, 255, 0.25);
}

#wrapper > .main.accent1 .box {
  border-color: rgba(255, 255, 255, 0.25);
}

#wrapper > .main.accent1 input[type="submit"],
#wrapper > .main.accent1 input[type="reset"],
#wrapper > .main.accent1 input[type="button"],
#wrapper > .main.accent1 button,
#wrapper > .main.accent1 .button {
  background-color: var(--main-color-hex);
  color: #ffffff !important;
}

#wrapper > .main.accent1 input[type="submit"]:hover,
#wrapper > .main.accent1 input[type="reset"]:hover,
#wrapper > .main.accent1 input[type="button"]:hover,
#wrapper > .main.accent1 button:hover,
#wrapper > .main.accent1 .button:hover {
  background-color: var(--main-color-hex);
  color: #ffffff !important;
}

#wrapper > .main.accent1 input[type="submit"]:active,
#wrapper > .main.accent1 input[type="reset"]:active,
#wrapper > .main.accent1 input[type="button"]:active,
#wrapper > .main.accent1 button:active,
#wrapper > .main.accent1 .button:active {
  background-color: #348bde;
}

#wrapper > .main.accent1 input[type="submit"].alt,
#wrapper > .main.accent1 input[type="reset"].alt,
#wrapper > .main.accent1 input[type="button"].alt,
#wrapper > .main.accent1 button.alt,
#wrapper > .main.accent1 .button.alt {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.25);
  color: #ffffff !important;
}

#wrapper > .main.accent1 input[type="submit"].alt:hover,
#wrapper > .main.accent1 input[type="reset"].alt:hover,
#wrapper > .main.accent1 input[type="button"].alt:hover,
#wrapper > .main.accent1 button.alt:hover,
#wrapper > .main.accent1 .button.alt:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

#wrapper > .main.accent1 input[type="submit"].alt.icon:before,
#wrapper > .main.accent1 input[type="reset"].alt.icon:before,
#wrapper > .main.accent1 input[type="button"].alt.icon:before,
#wrapper > .main.accent1 button.alt.icon:before,
#wrapper > .main.accent1 .button.alt.icon:before {
  color: #ffffff;
}

#wrapper > .main.accent1 label {
  color: #ffffff;
}

#wrapper > .main.accent1 input[type="text"],
#wrapper > .main.accent1 input[type="password"],
#wrapper > .main.accent1 input[type="email"],
#wrapper > .main.accent1 select,
#wrapper > .main.accent1 textarea {
  border-color: rgba(255, 255, 255, 0.25);
}

#wrapper > .main.accent1 input[type="text"]:focus,
#wrapper > .main.accent1 input[type="password"]:focus,
#wrapper > .main.accent1 input[type="email"]:focus,
#wrapper > .main.accent1 select:focus,
#wrapper > .main.accent1 textarea:focus {
  border-color: #ffffff;
  box-shadow: 0 0 0 1px #ffffff;
}

#wrapper > .main.accent1 select {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'%3E%3Cpath d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='rgba(255, 255, 255, 0.25)' /%3E%3C/svg%3E");
}

#wrapper > .main.accent1 select option {
  color: #ffffff;
  background: var(--main-color-hex);
}

#wrapper > .main.accent1 input[type="checkbox"] + label,
#wrapper > .main.accent1 input[type="radio"] + label {
  color: #ffffff;
}

#wrapper > .main.accent1 input[type="checkbox"] + label:before,
#wrapper > .main.accent1 input[type="radio"] + label:before {
  border-color: rgba(255, 255, 255, 0.25);
}

#wrapper > .main.accent1 input[type="checkbox"]:checked + label:before,
#wrapper > .main.accent1 input[type="radio"]:checked + label:before {
  background-color: #ffffff;
  border-color: #ffffff;
  box-shadow: 0 0 0 1px #ffffff;
  color: var(--main-color-hex);
}

#wrapper > .main.accent1 input[type="checkbox"]:focus + label:before,
#wrapper > .main.accent1 input[type="radio"]:focus + label:before {
  border-color: #ffffff;
  box-shadow: 0 0 0 1px #ffffff;
}

#wrapper > .main.accent1 ::-webkit-input-placeholder {
  color: #ffffff !important;
}

#wrapper > .main.accent1 :-moz-placeholder {
  color: #ffffff !important;
}

#wrapper > .main.accent1 ::-moz-placeholder {
  color: #ffffff !important;
}

#wrapper > .main.accent1 :-ms-input-placeholder {
  color: #ffffff !important;
}

#wrapper > .main.accent1 .formerize-placeholder {
  color: #ffffff !important;
}

#wrapper > .main.accent1 ul.alt li {
  border-top-color: rgba(255, 255, 255, 0.25);
}

#wrapper > .main.accent1 table tbody tr {
  border-color: rgba(255, 255, 255, 0.25);
}

#wrapper > .main.accent1 table tbody tr:nth-child(2n + 1) {
  background-color: rgba(255, 255, 255, 0.075);
}

#wrapper > .main.accent1 table th {
  color: #ffffff;
}

#wrapper > .main.accent1 table thead {
  border-bottom-color: rgba(255, 255, 255, 0.25);
}

#wrapper > .main.accent1 table tfoot {
  border-top-color: rgba(255, 255, 255, 0.25);
}

#wrapper > .main.accent1 table.alt tbody tr td {
  border-color: rgba(255, 255, 255, 0.25);
}

#wrapper > .main.accent1 .post > .content header .category {
  color: var(--main-color-hex);
}

#wrapper > .main.accent1 .post.style2 {
  background-color: var(--main-color-hex);
}

@media screen and (max-width: 480px) {
  #wrapper > .main.accent1 .post {
    border-top-color: rgba(255, 255, 255, 0.25);
  }
}

#wrapper > .main.accent2 {
  background-color: #292f33;
  color: #ffffff;
}

#wrapper > .main.accent2 input,
#wrapper > .main.accent2 select,
#wrapper > .main.accent2 textarea {
  color: #ffffff;
}

#wrapper > .main.accent2 a {
  color: inherit;
}

#wrapper > .main.accent2 a:hover {
  color: var(--main-color-hex) !important;
}

#wrapper > .main.accent2 strong,
#wrapper > .main.accent2 b {
  color: #ffffff;
}

#wrapper > .main.accent2 h1,
#wrapper > .main.accent2 h2,
#wrapper > .main.accent2 h3,
#wrapper > .main.accent2 h4,
#wrapper > .main.accent2 h5,
#wrapper > .main.accent2 h6 {
  color: #ffffff;
}

#wrapper > .main.accent2 blockquote {
  border-left-color: rgba(255, 255, 255, 0.125);
}

#wrapper > .main.accent2 code {
  background: rgba(255, 255, 255, 0.075);
  border-color: rgba(255, 255, 255, 0.125);
}

#wrapper > .main.accent2 hr {
  border-bottom-color: rgba(255, 255, 255, 0.125);
}

#wrapper > .main.accent2 .box {
  border-color: rgba(255, 255, 255, 0.125);
}

#wrapper > .main.accent2 input[type="submit"],
#wrapper > .main.accent2 input[type="reset"],
#wrapper > .main.accent2 input[type="button"],
#wrapper > .main.accent2 button,
#wrapper > .main.accent2 .button {
  background-color: var(--main-color-hex);
  color: #ffffff !important;
}

#wrapper > .main.accent2 input[type="submit"]:hover,
#wrapper > .main.accent2 input[type="reset"]:hover,
#wrapper > .main.accent2 input[type="button"]:hover,
#wrapper > .main.accent2 button:hover,
#wrapper > .main.accent2 .button:hover {
  background-color: var(--main-color-hex);
  color: #ffffff !important;
}

#wrapper > .main.accent2 input[type="submit"]:active,
#wrapper > .main.accent2 input[type="reset"]:active,
#wrapper > .main.accent2 input[type="button"]:active,
#wrapper > .main.accent2 button:active,
#wrapper > .main.accent2 .button:active {
  background-color: #348bde;
}

#wrapper > .main.accent2 input[type="submit"].alt,
#wrapper > .main.accent2 input[type="reset"].alt,
#wrapper > .main.accent2 input[type="button"].alt,
#wrapper > .main.accent2 button.alt,
#wrapper > .main.accent2 .button.alt {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.125);
  color: #ffffff !important;
}

#wrapper > .main.accent2 input[type="submit"].alt:hover,
#wrapper > .main.accent2 input[type="reset"].alt:hover,
#wrapper > .main.accent2 input[type="button"].alt:hover,
#wrapper > .main.accent2 button.alt:hover,
#wrapper > .main.accent2 .button.alt:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

#wrapper > .main.accent2 input[type="submit"].alt.icon:before,
#wrapper > .main.accent2 input[type="reset"].alt.icon:before,
#wrapper > .main.accent2 input[type="button"].alt.icon:before,
#wrapper > .main.accent2 button.alt.icon:before,
#wrapper > .main.accent2 .button.alt.icon:before {
  color: #ffffff;
}

#wrapper > .main.accent2 label {
  color: #ffffff;
}

#wrapper > .main.accent2 input[type="text"],
#wrapper > .main.accent2 input[type="password"],
#wrapper > .main.accent2 input[type="email"],
#wrapper > .main.accent2 select,
#wrapper > .main.accent2 textarea {
  border-color: rgba(255, 255, 255, 0.125);
}

#wrapper > .main.accent2 input[type="text"]:focus,
#wrapper > .main.accent2 input[type="password"]:focus,
#wrapper > .main.accent2 input[type="email"]:focus,
#wrapper > .main.accent2 select:focus,
#wrapper > .main.accent2 textarea:focus {
  border-color: var(--main-color-hex);
  box-shadow: 0 0 0 1px var(--main-color-hex);
}

#wrapper > .main.accent2 select {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'%3E%3Cpath d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='rgba(255, 255, 255, 0.125)' /%3E%3C/svg%3E");
}

#wrapper > .main.accent2 select option {
  color: #ffffff;
  background: #292f33;
}

#wrapper > .main.accent2 input[type="checkbox"] + label,
#wrapper > .main.accent2 input[type="radio"] + label {
  color: #ffffff;
}

#wrapper > .main.accent2 input[type="checkbox"] + label:before,
#wrapper > .main.accent2 input[type="radio"] + label:before {
  border-color: rgba(255, 255, 255, 0.125);
}

#wrapper > .main.accent2 input[type="checkbox"]:checked + label:before,
#wrapper > .main.accent2 input[type="radio"]:checked + label:before {
  background-color: var(--main-color-hex);
  border-color: var(--main-color-hex);
  box-shadow: 0 0 0 1px var(--main-color-hex);
  color: #ffffff;
}

#wrapper > .main.accent2 input[type="checkbox"]:focus + label:before,
#wrapper > .main.accent2 input[type="radio"]:focus + label:before {
  border-color: var(--main-color-hex);
  box-shadow: 0 0 0 1px var(--main-color-hex);
}

#wrapper > .main.accent2 ::-webkit-input-placeholder {
  color: #ffffff !important;
}

#wrapper > .main.accent2 :-moz-placeholder {
  color: #ffffff !important;
}

#wrapper > .main.accent2 ::-moz-placeholder {
  color: #ffffff !important;
}

#wrapper > .main.accent2 :-ms-input-placeholder {
  color: #ffffff !important;
}

#wrapper > .main.accent2 .formerize-placeholder {
  color: #ffffff !important;
}

#wrapper > .main.accent2 ul.alt li {
  border-top-color: rgba(255, 255, 255, 0.125);
}

#wrapper > .main.accent2 table tbody tr {
  border-color: rgba(255, 255, 255, 0.125);
}

#wrapper > .main.accent2 table tbody tr:nth-child(2n + 1) {
  background-color: rgba(255, 255, 255, 0.075);
}

#wrapper > .main.accent2 table th {
  color: #ffffff;
}

#wrapper > .main.accent2 table thead {
  border-bottom-color: rgba(255, 255, 255, 0.125);
}

#wrapper > .main.accent2 table tfoot {
  border-top-color: rgba(255, 255, 255, 0.125);
}

#wrapper > .main.accent2 table.alt tbody tr td {
  border-color: rgba(255, 255, 255, 0.125);
}

#wrapper > .main.accent2 .post > .content header .category {
  color: var(--main-color-hex);
}

#wrapper > .main.accent2 .post.style2 {
  background-color: #292f33;
}

@media screen and (max-width: 480px) {
  #wrapper > .main.accent2 .post {
    border-top-color: rgba(255, 255, 255, 0.125);
  }
}

#wrapper > .main.alt {
  background-image: var(--background-image);
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
}

@media screen and (max-width: 1280px) {
  #wrapper > .main {
    padding: 6rem 3rem 4rem 3rem;
  }

  #wrapper > .main > .inner.style2 > .image.main:first-child {
    height: 20rem;
  }

  #wrapper > .main.alt {
    background-attachment: scroll;
  }
}

@media screen and (max-width: 980px) {
  #wrapper > .main > .inner.style2 {
    padding: 4rem 3rem 2rem 3rem;
  }

  #wrapper > .main > .inner.style2 > .image.main:first-child {
    top: -4rem;
    left: -3rem;
    width: calc(100% + 6rem);
    height: 25rem;
  }
}

@media screen and (max-width: 736px) {
  #wrapper > .main {
    padding: 4rem 2rem 2rem 2rem;
  }

  #wrapper > .main > .inner.style2 {
    padding: 2.5rem 2rem 0.5rem 2rem;
  }

  #wrapper > .main > .inner.style2 > .image.main:first-child {
    top: -2.5rem;
    left: -2rem;
    width: calc(100% + 4rem);
    height: 18rem;
  }
}

@media screen and (max-width: 480px) {
  #wrapper > .main > .inner.style2 > .image.main:first-child {
    height: 14rem;
  }
}

@media screen and (max-width: 360px) {
  #wrapper > .main {
    padding: 3rem 1rem 1rem 1rem;
  }
}

/* Wrapper */

#wrapper {
  background-color: #ffffff;
}

/* Header */

@-moz-keyframes reveal-header {
  0% {
    top: -4em;
    opacity: 0;
  }

  100% {
    top: 0;
    opacity: 1;
  }
}

@-webkit-keyframes reveal-header {
  0% {
    top: -4em;
    opacity: 0;
  }

  100% {
    top: 0;
    opacity: 1;
  }
}

@-ms-keyframes reveal-header {
  0% {
    top: -4em;
    opacity: 0;
  }

  100% {
    top: 0;
    opacity: 1;
  }
}

@keyframes reveal-header {
  0% {
    top: -4em;
    opacity: 0;
  }

  100% {
    top: 0;
    opacity: 1;
  }
}

body {
  padding-top: 3.25rem;
}

@media screen and (max-width: 736px) {
  body {
    padding-top: 44px;
  }
}

#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3.25rem;
  line-height: 3.25rem;
  background-color: #ffffff;
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.125);
  cursor: default;
  z-index: 10000;
}

#header a {
  display: block;
  height: inherit;
  line-height: inherit;
  border-bottom: 0;
}

#header > .logo {
  position: absolute;
  left: 1rem;
}

#header > .logo strong {
  font-family: "Raleway", Helvetica, sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.025rem;
}

#header > nav {
  position: absolute;
  right: 0;
}

#header > nav > ul {
  list-style: none;
  padding: 0 0.75rem 0 0;
  margin: 0;
}

#header > nav > ul > li {
  position: relative;
  display: inline-block;
  margin: 0 0.875rem;
  padding: 0;
}

#header > nav > ul > li > a {
  overflow: hidden;
}

#header > nav > ul > li > a.icon:before {
  float: right;
  margin-left: 0.5rem;
  line-height: inherit;
  color: #bfbfbf;
}

#header > nav > ul > li > a.icon:hover:before {
  color: inherit;
}

#header > nav > ul > li > ul {
  display: none;
}

#header > nav > ul > li.current:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: -0.25rem;
  width: calc(100% + 0.5rem);
  height: 0.25rem;
  background-color: var(--main-color-hex);
  border-radius: 4px;
}

#header > nav > ul > li.active > a {
  color: var(--main-color-hex);
}

.dropotron {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 0.075rem 0.75rem 0 rgba(0, 0, 0, 0.125);
  list-style-type: none;
  margin: -0.5rem 0 0 1px;
  min-width: 10rem;
  padding: 0.5rem 0;
}

.dropotron.level-0 {
  margin-top: 1.125rem;
  margin-left: 0;
}

.dropotron.level-0:before {
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #ffffff;
  box-shadow: -2px -2px 3px -1px rgba(0, 0, 0, 0.1);
  content: "";
  position: absolute;
  top: -0.375rem;
  right: 2rem;
  width: 0.75rem;
  height: 0.75rem;
}

.dropotron li {
  border-top: solid 1px rgba(143, 143, 143, 0.25);
  padding: 0;
  position: relative;
}

.dropotron li:first-child {
  border-top: none;
}

.dropotron li.active > a {
  color: var(--main-color-hex);
}

.dropotron a {
  color: #3f3f3f;
  padding: 0.5rem 1rem;
  text-decoration: none !important;
  border: 0;
}

.dropotron a:active,
.dropotron a:hover,
.dropotron a:focus {
  color: var(--main-color-hex);
}

#header.reveal {
  -moz-animation: reveal-header 0.5s ease;
  -webkit-animation: reveal-header 0.5s ease;
  -ms-animation: reveal-header 0.5s ease;
  animation: reveal-header 0.5s ease;
}

#header.alt {
  background-color: #292f33;
  color: #ffffff;
  -moz-animation: none;
  -webkit-animation: none;
  -ms-animation: none;
  animation: none;
  box-shadow: none;
  background-color: transparent;
  position: absolute;
}

#header.alt input,
#header.alt select,
#header.alt textarea {
  color: #ffffff;
}

#header.alt a {
  color: inherit;
}

#header.alt a:hover {
  color: var(--main-color-hex) !important;
}

#header.alt strong,
#header.alt b {
  color: #ffffff;
}

#header.alt h1,
#header.alt h2,
#header.alt h3,
#header.alt h4,
#header.alt h5,
#header.alt h6 {
  color: #ffffff;
}

#header.alt blockquote {
  border-left-color: rgba(255, 255, 255, 0.125);
}

#header.alt code {
  background: rgba(255, 255, 255, 0.075);
  border-color: rgba(255, 255, 255, 0.125);
}

#header.alt hr {
  border-bottom-color: rgba(255, 255, 255, 0.125);
}

#header.alt > .logo {
  top: 1rem;
  left: 2rem;
}

#header.alt > nav {
  top: 1rem;
  right: 1rem;
}

#header.alt > nav > ul {
  padding: 0 0.5rem 0 0;
}

#header.alt > nav > ul > li {
  margin: 0 1.25rem;
}

#header.alt > nav > ul > li.current:after {
  bottom: 0.25rem;
}

#header.alt > .navPanelToggle {
  top: 1rem;
  right: 1rem;
}

#header > .navPanelToggle {
  -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    top 0.2s ease-in-out, right 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    top 0.2s ease-in-out, right 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    top 0.2s ease-in-out, right 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    top 0.2s ease-in-out, right 0.2s ease-in-out;
  text-decoration: none;
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  height: inherit;
  line-height: inherit;
  padding: 0 1rem;
  outline: 0;
}

#header > .navPanelToggle:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}

#header > .navPanelToggle:before {
  content: "\f0c9";
  margin: 0 0 0 0.625rem;
  float: right;
}

@media screen and (max-width: 980px) {
  #header > nav {
    display: none;
  }

  #header > .navPanelToggle {
    display: block;
  }
}

@media screen and (max-width: 736px) {
  #header {
    height: 44px;
    line-height: 44px;
  }

  #header > .logo {
    left: 1rem;
  }

  #header > .logo .extra {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  #header.alt > .logo {
    top: 0;
    left: 1rem;
  }

  #header.alt > .navPanelToggle {
    top: 0;
    right: 0;
  }
}

/* Banner */

#banner {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  background-image: var(--banner-image);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  padding: 8rem 0;
  overflow: hidden;
  position: relative;
}

#banner > .content {
  background-color: var(--main-color-hex);
  color: #ffffff;
  -moz-transition: -moz-transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  -webkit-transition: -webkit-transform 0.5s ease-in-out,
    opacity 0.5s ease-in-out;
  -ms-transition: -ms-transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  padding: 2.75rem;
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 1;
  margin-top: 2rem;
  opacity: 1;
}

#banner > .content input,
#banner > .content select,
#banner > .content textarea {
  color: #ffffff;
}

#banner > .content a {
  color: inherit;
}

#banner > .content a:hover {
  color: var(--main-color-hex) !important;
}

#banner > .content strong,
#banner > .content b {
  color: #ffffff;
}

#banner > .content h1,
#banner > .content h2,
#banner > .content h3,
#banner > .content h4,
#banner > .content h5,
#banner > .content h6 {
  color: #ffffff;
}

#banner > .content blockquote {
  border-left-color: rgba(255, 255, 255, 0.25);
}

#banner > .content code {
  background: rgba(255, 255, 255, 0.075);
  border-color: rgba(255, 255, 255, 0.25);
}

#banner > .content hr {
  border-bottom-color: rgba(255, 255, 255, 0.25);
}

#banner > .content > * {
  margin: 0.25rem 0 0 0;
}

#banner > .content > :first-child {
  margin-top: 0;
}

#banner > .content > :last-child {
  margin-bottom: 0;
}

#banner > .content h1 {
  font-size: 2.25rem;
}

#banner > .content p {
  font-size: 1.25rem;
}

#banner > .more {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg zoomAndPan='disable' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='40px' height='20px' viewBox='0 0 40px 20px'%3E%3Cstyle type='text/css'%3Eline %7B stroke: %23ffffff%3B stroke-width: 0.75px%3B %7D%3C/style%3E%3Cline x1='0' y1='0' x2='20' y2='20' /%3E%3Cline x1='20' y1='20' x2='40' y2='0' /%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 3rem 1.5rem;
  width: 6rem;
  height: 6rem;
  text-align: center;
  color: #ffffff;
  border-bottom: 0;
  position: absolute;
  bottom: 0;
  left: calc(50% - 3rem);
  text-indent: 6rem;
  white-space: nowrap;
  overflow: hidden;
  z-index: 1;
}

#banner:before {
  pointer-events: none;
  -moz-transition: opacity 2s ease-in-out;
  -webkit-transition: opacity 2s ease-in-out;
  -ms-transition: opacity 2s ease-in-out;
  transition: opacity 2s ease-in-out;
  -moz-transition-delay: 0.5s;
  -webkit-transition-delay: 0.5s;
  -ms-transition-delay: 0.5s;
  transition-delay: 0.5s;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #3f3f3f;
  opacity: 0;
}

#banner:after {
  pointer-events: none;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.625),
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.625)
  );
}

#header + #banner {
  margin-top: -3.25rem;
}

body.is-preload #banner > .content {
  -moz-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  opacity: 0;
}

body.is-preload #banner:before {
  opacity: 1;
}

@media screen and (max-width: 1280px) {
  #banner {
    background-attachment: scroll;
  }
}

@media screen and (max-width: 980px) {
  #banner > .content {
    margin-top: 0;
  }
}

@media screen and (max-width: 736px) {
  #banner > .content {
    padding: 1.75rem;
  }

  #banner > .content h1 {
    font-size: 1.75rem;
  }

  #banner > .content p {
    font-size: 1rem;
  }

  #header + #banner {
    margin-top: -44px;
  }
}

@media screen and (max-width: 480px) {
  #banner {
    padding: 12rem 0;
    min-height: 0;
  }

  #banner > .more {
    display: none;
  }
}

/* Footer */

#footer {
  padding: 3.5rem 0 1.5rem 0;
  color: #bfbfbf;
  text-align: center;
}

#footer ul.icons {
  margin-bottom: 1rem;
}

#footer a {
  color: inherit;
}

@media screen and (max-width: 1280px) {
  #footer {
    padding: 3rem 3rem 1rem 3rem;
  }
}

@media screen and (max-width: 736px) {
  #footer {
    padding: 2rem 1.75rem 0.1rem 1.75rem;
  }
}

/* Nav Panel */

#wrapper {
  -moz-transition: opacity 0.5s ease;
  -webkit-transition: opacity 0.5s ease;
  -ms-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}

#navPanel {
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  -moz-transition: -moz-transform 0.5s ease, box-shadow 0.5s ease,
    visibility 0.5s;
  -webkit-transition: -webkit-transform 0.5s ease, box-shadow 0.5s ease,
    visibility 0.5s;
  -ms-transition: -ms-transform 0.5s ease, box-shadow 0.5s ease, visibility 0.5s;
  transition: transform 0.5s ease, box-shadow 0.5s ease, visibility 0.5s;
  -webkit-overflow-scrolling: touch;
  background: var(--main-color-hex);
  box-shadow: none;
  color: #ffffff;
  height: 100%;
  max-width: 80%;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  width: 20em;
  z-index: 10002;
}

#navPanel nav {
  padding: 3em 2em;
}

#navPanel .link {
  -moz-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  -ms-transition: color 0.2s;
  transition: color 0.2s;
  border-top: solid 1px rgba(255, 255, 255, 0.25);
  border-bottom: 0;
  color: inherit !important;
  display: block;
  padding: 0.75em 0;
  text-decoration: none;
}

#navPanel .link:first-child {
  border-top: 0;
}

#navPanel .link.depth-0 {
  color: #ffffff !important;
  font-weight: 600;
}

#navPanel .link:active,
#navPanel .link:focus,
#navPanel .link:hover {
  color: #ffffff !important;
}

#navPanel .link .indent-1 {
  display: inline-block;
  width: 1.25em;
}

#navPanel .link .indent-2 {
  display: inline-block;
  width: 2.5em;
}

#navPanel .close {
  text-decoration: none;
  -moz-transition: color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border: 0;
  color: #ffffff;
  cursor: pointer;
  display: block;
  height: 3.25em;
  line-height: 3.25em;
  padding-right: 1.25em;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
  vertical-align: middle;
  width: 7em;
}

#navPanel .close:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}

#navPanel .close:before {
  content: "\f00d";
  font-size: 1.25em;
}

#navPanel .close:hover {
  color: #ffffff !important;
}

@media screen and (max-width: 736px) {
  #navPanel .close {
    height: 4em;
    line-height: 4em;
  }
}

@media screen and (max-width: 736px) {
  #navPanel nav {
    padding: 2.5em 1.75em;
  }
}

body.is-navPanel-visible #wrapper {
  pointer-events: none;
  cursor: default;
  opacity: 0.25;
}

body.is-navPanel-visible #navPanel {
  -moz-transform: translateX(0);
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  visibility: visible;
}
